import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Grid } from "@mui/material";
import ProjectTab from "../components/tabs/ProjectsTab";
import MainButton from "../components/buttons/MainButton";
import ProjectsTable from "../components/tables/ProjectsTable";
import LastAnalysisCards from "../components/homedata/LastAnalysisCards";
import CreateProjectForm from "../components/homedata/CreateProject";
import { useGetAllProjects } from "../hooks/project/useGetAllProjects";
import { useGetProject } from "../hooks/project/useGetProject";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { EvaluationDataWithProjectName, ProjectData } from "@/types";
import { useDispatch, useSelector } from "react-redux";
import { setAllProjects, setCurrentProject, setFeatchProjects } from "../store/projectSlice";
import { RootState } from "../store/store";
import styles from "./css/HomePage.module.css";
import { useGetLatestEvaluations } from "../hooks/evalDetect/useGetLatestEvaluations";


const HomePage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allProjects } = useSelector((state: RootState) => state.project);
  const user_id = useSelector((state: RootState) => state.user.id);
  const [openCreateProjectForm, setOpenCreateProjectForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState("ME");
  const [filteredMe, setFilteredMe] = useState<ProjectData[]>([]);
  const [filteredDe, setFilteredDe] = useState<ProjectData[]>([]);
  const [filteredBe, setFilteredBe] = useState<ProjectData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [latestEvaluations, setLatestEvaluations] = useState<EvaluationDataWithProjectName[] | null>(null);

  const getAllProjectMutation = useGetAllProjects({
    onSuccess(data) {
      dispatch(setAllProjects(data));
      setIsLoading(false);
      dispatch(setFeatchProjects(false));
    },
    onError(error) {
      setIsLoading(false);
    },
  });

  const getProjectMutation = useGetProject({
    onSuccess(data) {
      dispatch(setCurrentProject(data));
      navigate(`/${selectedItem.toLowerCase()}/${data.id}`);
    },
    onError(error) {
      setIsLoading(true);
      getAllProjectMutation.mutate();
    }
  });

  const getLatestEvaluationsMutation = useGetLatestEvaluations({
    onSuccess(data) {
      setLatestEvaluations(data);
    },
    onError(error) {
      console.log(error);
    }
  });

  useEffect(() => {
    // const hasVisited = sessionStorage.getItem('hasVisited') || false;
    if (true) {
      setIsLoading(true);
      setFeatchProjects(false);
      sessionStorage.setItem('hasVisited', 'true');
      getAllProjectMutation.mutate();
      let projects: { [key: string]: string } = {}
      allProjects?.forEach((project) => {
        projects[project.project_name] = project.id;
      });
      getLatestEvaluationsMutation.mutate({user_id: user_id as string, project_ids: projects});
    }
  }, []);

  useEffect(() => {
    setOpenCreateProjectForm(false);
    setSelectedItem("ME");
  }, []);

  useEffect(() => {
    setFilteredMe(allProjects ? allProjects.filter((project) => project.project_type === "ME") : []);
    setFilteredDe(allProjects ? allProjects.filter((project) => project.project_type === "DE") : []);
    setFilteredBe(allProjects ? allProjects.filter((project) => project.project_type === "BE") : []);
  }, [allProjects]);

  const isProjectsTableType = (type: string): type is "ME" | "BE" | "DE" => {
    return type === "ME" || type === "BE" || type === "DE";
  };

  const handleOpenCreateProjectForm = () => {
    setOpenCreateProjectForm(true);
  };

  const handleCloseCreateProjectForm = (project_id: string | null) => {
    setIsLoading(true);
    if(project_id){
      getProjectMutation.mutate(project_id);
    }
    getAllProjectMutation.mutate();
    setOpenCreateProjectForm(false);
  };

  return (
    <Container className={styles.outerContainer} maxWidth={false}>
      <Grid container spacing={1} className={styles.topRow}>
        <Grid item xs={9} className={styles.flexContainer}>
          <ProjectTab
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
          />
        </Grid>
        <Grid item xs={3} className={styles.buttonWrapper}>
        <MainButton
            theme="blue"
            label={`Create New ${selectedItem} Project`}
            IconComponent={AddCircleIcon}
            className={`${selectedItem === "LA" ? styles.hidden : ""} ${styles.button}`}
            onClick={handleOpenCreateProjectForm}
          />
          <CreateProjectForm
            type={selectedItem as ("ME"| "DE"| "BE")}
            open={openCreateProjectForm}
            handleClose={handleCloseCreateProjectForm}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className={styles.tableWrapper}>
        {selectedItem === "LA" ? (
          (latestEvaluations && <LastAnalysisCards analysisData={latestEvaluations} className={styles.cardsContainer} />)
        ) : (
          isProjectsTableType(selectedItem) && (
            <ProjectsTable
              key={selectedItem}
              type={selectedItem}
              projects={selectedItem === "ME"? filteredMe: selectedItem === "DE"? filteredDe: filteredBe}
              isLoading={isLoading}
              latestEvaluations={latestEvaluations}
            />
          )
        )}
      </Grid>
    </Container>
  );
};
export default HomePage;
