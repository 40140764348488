import React, { useState } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import SelectButton from "../../buttons/SelectButton";
import UploadButton from "../../buttons/UploadButton";
import TooltipWrapper from "../../assets/TooltipWrapper";
import styles from "./css/LossModal.module.css";
import MainButton from "../../buttons/MainButton";
import { TbDownload } from "react-icons/tb";
import { useGenerateURL } from "../../../hooks/configMng/useGenerateURL";

interface LossModalProps {
  data: any;
  setData: React.Dispatch<React.SetStateAction<any>>;
  onSave: () => void;
  onClose: () => void;
  open: boolean;
  loading: boolean;
}

const LossModal: React.FC<LossModalProps> = ({
  data,
  setData,
  onSave,
  onClose,
  open,
  loading,
}) => {
  const builtInDataTensorKeras = [
    "BinaryCrossentropy",
    "SigmoidFocalCrossEntropy",
    "CategoricalCrossentropy",
    "CategoricalHinge",
    "CosineSimilarity",
    "Hinge",
    "Poisson",
    "SparseCategoricalCrossentropy",
    "SquaredHinge",
  ];

  const builtInDataPytorch = [
    "Loss",
    "L1Loss",
    "KLDivLoss",
    "MSELoss_",
    "HuberLoss",
    "SmoothL1Loss",
    "MarginRankingLoss",
    "MultiMarginLoss",
    "MultiLabelSoftMarginLoss",
    "NLLLoss",
    "GaussianNLLLoss",
    "PoissonNLLLoss",
    "CrossEntropyLoss",
    "BCELoss",
    "BCEWithLogitsLoss",
    "CosineEmbeddingLoss",
    "CTCLoss",
    "HingeEmbeddingLoss",
    "SoftMarginLoss",
    "TripletMarginLoss",
    "TripletMarginWithDistanceLoss",
  ];
  const [selectedType, setSelectedType] = useState<"builtIn" | "custom">(
    "builtIn"
  );
  const [error, setError] = useState("");

  const templatesPath = "Loss/";
  const files = {
    PyTorch: "torch/pytorch_loss.py",
    TensorFlow: "TF/tensorflow_loss.py",
    Keras: "TF/tensorflow_loss.py",
  };

  const handleUpload = (file: File) => {
    const fileExtension = file.name.split(".").pop();
    if (fileExtension !== "py") {
      setError("The loss file for should be a .py file.");
    } else {
      setData({
        ...data,
        built_in_loss: "",
        loss: file,
        loss_name: file.name,
      });
      setError("");
    }
  };

  const handleSelectChange = (value: string) => {
    setData({
      ...data,
      built_in_loss: value,
      loss: null,
      loss_name: "",
    });
  };

  const handleDownload = (url: string, filename: string) => {
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const generateURL = useGenerateURL({
    onSuccess(data) {
      handleDownload(data.url, files[data.framework as "PyTorch" | "Keras" | "TensorFlow"]);
    },
    onError(error) {
      console.log(`Error fetching URL: ${error}`);
    },
  });

  const handleDownloadTemplate = () => {
    const framework = data.framework;
    let templatePath = templatesPath + files[framework as keyof typeof files];
    generateURL.mutate({ file_url: templatePath });
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedType(event.target.value as "builtIn" | "custom");
  };

  return (
    <Dialog open={open} onClose={() => {}} className={styles.dialog} fullWidth>
      <DialogTitle>
        Loss Configuration
        <IconButton onClick={onClose} className={styles.closeButton}>
          X
        </IconButton>
        <RadioGroup value={selectedType} onChange={handleTypeChange} row>
          <TooltipWrapper tooltipText="Select a built-in loss function. This function will be used to attack the model">
            <FormControlLabel
              value="builtIn"
              control={<Radio />}
              label="Built In"
            />
          </TooltipWrapper>

          <TooltipWrapper tooltipText="Upload python file that contains the definition of your loss function">
            <FormControlLabel
              value="custom"
              control={<Radio />}
              label="Custom"
            />
          </TooltipWrapper>
        </RadioGroup>
      </DialogTitle>
      <DialogContent>
        <Box className={styles.container}>
          <Box className={styles.columnWrapper}>
            {selectedType === "builtIn" ? (
              <>
                <Box className={styles.column}>
                  <Typography>Select Type</Typography>
                  <SelectButton
                    data={
                      data.framework === "PyTorch"
                        ? builtInDataPytorch
                        : data.framework === "TensorFlow" ||
                          data.framework === "Keras"
                        ? builtInDataTensorKeras
                        : []
                    }
                    selectedValue={data.built_in_loss}
                    className={styles.selection}
                    listClassName={styles.listSelection}
                    updateFunction={(value) => handleSelectChange(value)}
                    isUpload={false}
                  />
                </Box>
                <div className={styles.errorMessage}>{error}</div>
                <Typography>Loss Function Template</Typography>
                <MainButton
                  label="Download Template"
                  theme="black"
                  className={`${styles.button} ${styles.template} ${styles.builtInTemplate}`}
                  onClick={handleDownloadTemplate}
                  IconComponent={TbDownload}
                />
              </>
            ) : (
              <Box className={styles.column}>
                <Typography>Upload Loss Function</Typography>
                <UploadButton
                  updateFunction={(value) => {}}
                  selectedValue={data.loss_name}
                  onUpload={(file) => handleUpload(file)}
                  selection={false}
                />

                <div className={styles.errorMessage}>{error}</div>
                <Typography>Loss Function Template</Typography>
                <MainButton
                  label="Download Template"
                  theme="black"
                  className={`${styles.button} ${styles.template}`}
                  onClick={handleDownloadTemplate}
                  IconComponent={TbDownload}
                />
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <MainButton
          onClick={onSave}
          theme="blue"
          label="Save"
          className={styles.saveButton}
          isLoading={loading}
          disabled={loading}
        />
      </DialogActions>
    </Dialog>
  );
};

export default LossModal;
