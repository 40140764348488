import React from "react";
import { ButtonBase, ButtonBaseProps, CircularProgress } from "@mui/material";
import styles from "./css/MainButton.module.css";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

interface MainButtonProps extends ButtonBaseProps {
  label: string;
  IconComponent?: React.ElementType;
  theme: "blue" | "white" | "black";
  disabled?: boolean;
  className?: string;
  spinnerClassName?: string;
  onClick: () => void;
  isLoading?: boolean;
}

const MainButton: React.FC<MainButtonProps> = ({
  label,
  IconComponent,
  theme,
  onClick,
  className,
  spinnerClassName,
  disabled,
  isLoading = false,
  ...props
}) => {
  const helpEnabled = useSelector((state: RootState) => state.help.enabled); // Get help mode state from redux

  return (
    <ButtonBase
      disabled={disabled || isLoading}
      className={`${styles.button} 
        ${
          theme === "blue"
            ? styles.blue
            : theme === "white"
            ? styles.white
            : styles.black
        } ${className}
        ${disabled || isLoading ? styles.disabled : ""}
        ${helpEnabled && label === "Help" ? styles.pulse : ""}`} // Apply pulse class when help mode is enabled
      onClick={onClick}
      {...props}
    >
      {isLoading && (
        <CircularProgress size={24} className={`${styles.spinner} ${spinnerClassName}`} />
      )}
      <div className={`${styles.buttonContent} ${isLoading ? styles.hiddenContent : ""}`}>
        {label}
        {IconComponent && <IconComponent className={styles.icon} />}
      </div>
    </ButtonBase>
  );
};

export default MainButton;
