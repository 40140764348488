import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Link,
  Divider,
  Box,
  Tooltip,
} from "@mui/material";
import styles from "./css/AnalysisCard.module.css";
import { truncateText } from "../../utils/truncateText";
import { EvaluationDataWithProjectName } from "@/types";
import { setCurrentProject } from "../../store/projectSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useNavigate } from "react-router-dom";
import DownloadButton from "../buttons/DownloadButton";

interface AnalysisCardProps {
  evaluation: EvaluationDataWithProjectName;
}

const AnalysisCard: React.FC<AnalysisCardProps> = ({ evaluation }) => {
  const date_splitted = evaluation.start_time.split(" ");
  const allProjects = useSelector(
    (state: RootState) => state.project.allProjects
  );
  const creation_date = date_splitted[0];
  const creation_time = date_splitted[1];
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleProjectClick = (project_id: string) => {
    if (allProjects) {
      const project = allProjects.find((project) => project.id === project_id);
      if (project) {
        dispatch(setCurrentProject(project));
        navigate(`/${project.project_type.toLowerCase()}/${project_id}`);
      }
    }
  };

  const getModeColor = () => {
    switch (evaluation.status) {
      case "Failed":
        return "#FF6F61";
      case "Completed":
        return "#6FCF97";
      case "Running":
        return "#F2C94C";
      case "stopped":
        return "#FF6F61";
      default:
        return "#A2ABBA";
    }
  };

  const getTruncatedError = (error: string) => {
    if (error && error.length > 30) {
      return (
        <Tooltip title={error}>
          <b>{truncateText(error, 30)}</b>
        </Tooltip>
      );
    }
    return <b>{error}</b>;
  };

  return (
    <Card
      className={styles.analysisCard}
      style={{ borderColor: getModeColor() }}
      elevation={0}
    >
      <CardHeader
        align="center"
        className={styles.analysisCardHeader}
        title={
          <Typography
            variant="h6"
            align="center"
            className={styles.analysisCardTypography}
          >
            {evaluation.status}
          </Typography>
        }
        style={{ backgroundColor: getModeColor() }}
      />
      <CardContent className={styles.analysisCardContent}>
        <Box className={styles.analysisCardBox}>
          <Typography
            variant="h6"
            color="text.secondary"
            align="center"
            className={styles.analysisCardTypography}
          >
            Status: <b>{evaluation.status}</b>
          </Typography>
        </Box>
        <Divider className={styles.analysisCardDivider} />
        <Box className={styles.analysisCardBox}>
          <Typography
            variant="h6"
            color="text.secondary"
            align="center"
            className={styles.analysisCardTypography}
          >
            Project:{" "}
            <Link
              href={""}
              onClick={(e) => handleProjectClick(evaluation.project_id)}
              className={styles.analysisCardLink}
            >
              <b>{truncateText(evaluation.project_name, 10)}</b>
            </Link>
            &nbsp;&nbsp;&nbsp;&nbsp;Analysis: <b>{evaluation.analysis_name}</b>
          </Typography>
        </Box>
        <Divider className={styles.analysisCardDivider} />
        <Box className={styles.analysisCardBox}>
          <Typography
            variant="h6"
            color="text.secondary"
            align="center"
            className={styles.analysisCardTypography}
          >
            Creation Date: <b>{creation_date}</b>
          </Typography>
        </Box>
        <Divider className={styles.analysisCardDivider} />
        <Box className={styles.analysisCardBox}>
          <Typography
            variant="h6"
            color="text.secondary"
            align="center"
            className={styles.analysisCardTypography}
          >
            Creation Time: <b>{creation_time}</b>
          </Typography>
        </Box>
        <Divider className={styles.analysisCardDivider} />
        <Divider className={styles.analysisCardDivider} />
        <Box className={styles.analysisCardBox}>
          <Typography
            variant="h6"
            color="text.secondary"
            align="center"
            className={styles.analysisCardTypography}
          >
            Elapsed Time: <b>{evaluation.elapsed_time}</b>
          </Typography>
        </Box>
        {evaluation.status === "Failed" && (
          <>
            <Box className={styles.analysisCardBox}>
              <Typography
                variant="h6"
                color="text.secondary"
                align="center"
                className={styles.analysisCardTypography}
              >
                Error: {getTruncatedError(evaluation.error as string)}
              </Typography>
            </Box>
          </>
        )}
        {evaluation.status === "Completed" && (
          <Box className={styles.analysisCardBox}>
            <Typography
              variant="h6"
              color="text.secondary"
              align="center"
              className={styles.analysisCardTypography}
            >
              Reports: {evaluation.report && <DownloadButton type="json" content={evaluation.report || {}} filename={evaluation.analysis_name}/>} {evaluation.pdf && <DownloadButton type="pdf" content={evaluation.pdf} filename={evaluation.analysis_name}/>}
            </Typography>
          </Box>
        )}
        <Divider className={styles.analysisCardDivider} />
      </CardContent>
    </Card>
  );
};

export default AnalysisCard;
