import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import AnalysisCard from "../cards/AnalysisCard";
import { EvaluationDataWithProjectName } from "@/types";
import styles from "./css/LastAnalysisCarsd.module.css";

interface AnalysisCardsGridProps {
  analysisData: EvaluationDataWithProjectName[];
  className?: string;
}

const LastAnalysisCards: React.FC<AnalysisCardsGridProps> = ({ analysisData ,className}) => {
  // Filter the data based on project types
  const modelEvaluationProjects = analysisData.filter(data => data.project_type === "ME");
  const dataEvaluationProjects = analysisData.filter(data => data.project_type === "DE");
  const biasEvaluationProjects = analysisData.filter(data => data.project_type === "BE");

  return (
    <Box className={`${styles.container} `}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={4} className={styles.headerContainer}>
          <Typography variant="h5" className={styles.header}>Model Evaluation Projects</Typography>
          <Box className={styles.cardsContainer}>
            {modelEvaluationProjects.map((data, index) => (
              <Box key={index} className={styles.cardItem}>
                <AnalysisCard evaluation={data} />
              </Box>
            ))}
          </Box>
        </Grid>

        <Grid item xs={12} md={4} className={styles.headerContainer}>
          <Typography variant="h5" className={styles.header}>Data Evaluation Projects</Typography>
          <Box className={styles.cardsContainer}>
            {dataEvaluationProjects.map((data, index) => (
              <Box key={index} className={styles.cardItem}>
                <AnalysisCard evaluation={data} />
              </Box>
            ))}
          </Box>
        </Grid>

        <Grid item xs={12} md={4} className={styles.headerContainer}>
          <Typography variant="h5" className={styles.header}>Bias Evaluation Projects</Typography>
          <Box className={styles.cardsContainer}>
            {biasEvaluationProjects.map((data, index) => (
              <Box key={index} className={styles.cardItem}>
                <AnalysisCard evaluation={data} />
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LastAnalysisCards;
